import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
  background: '#243842',
}));

// Data structure for loan breakdown
const FBN2023Data = [
  {
    sector: 'Retail (Secured & Unsecured)',
    amount: '1,133,987',
    currency: "E'000",
    performing: '961,183',
    nonPerforming: '72,653',
    provision: '100,151',
    coverageRatio: '15.2%',
    guarantee: '',
  },
  {
    sector: 'FNB Commercial',
    amount: '1,338,265',
    currency: "E'000",
    performing: '1,289,071',
    nonPerforming: '20,244',
    provision: '28,950',
    coverageRatio: '3.7%',
    guarantee: '',
  },
  {
    sector: 'Wesbank Commercial',
    amount: '338,933',
    currency: "E'000",
    performing: '305,275',
    nonPerforming: '15,098',
    provision: '18,560',
    coverageRatio: '9.9%',
    guarantee: '',
  },
  {
    sector: 'RMB Corporate Investment Banking',
    amount: '824,414',
    currency: "E'000",
    performing: '822,387',
    nonPerforming: '-',
    provision: '2,027',
    coverageRatio: '0.2%',
    guarantee: '',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const FBN2022Data = [
  {
    sector: 'Retail (Secured & Unsecured)',
    amount: '1,097,266',
    currency: "E'000",
    performing: '930,889',
    nonPerforming: '68,345',
    provision: '98,032',
    coverageRatio: '15.2%',
    guarantee: '',
  },
  {
    sector: 'FNB Commercial',
    amount: '1,142,224',
    currency: "E'000",
    performing: '1,082,281',
    nonPerforming: '18,820',
    provision: '41,123',
    coverageRatio: '5.2%',
    guarantee: '',
  },
  {
    sector: 'Wesbank Commercial',
    amount: '135,582',
    currency: "E'000",
    performing: '319,489',
    nonPerforming: '18,406',
    provision: '13,687',
    coverageRatio: '9.1%',
    guarantee: '',
  },
  {
    sector: 'RMB Corporate Investment Banking',
    amount: '405,898',
    currency: "E'000",
    performing: '399,971',
    nonPerforming: '-',
    provision: '5,927',
    coverageRatio: '1.5%',
    guarantee: '',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const ABC2023Data = [
  {
    sector: 'Mortgage lending',
    amount: '708,739',
    currency: "P'000",
    performing: '660,575',
    nonPerforming: '33,414',
    provision: '14,750',
    coverageRatio: '6.8%',
    guarantee: '',
  },
  {
    sector: 'Corporate lending',
    amount: '218,852',
    currency: "P'000",
    performing: '212,074',
    nonPerforming: '6,311',
    provision: '467',
    coverageRatio: '3.1%',
    guarantee: '',
  },
  {
    sector: 'Retail and SME lending',
    amount: '5,435,326',
    currency: "P'000",
    performing: '5,134,388',
    nonPerforming: '87,877',
    provision: '213,061',
    coverageRatio: '5.5%',
    guarantee: '266,941',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const ABC2022Data = [
  {
    sector: 'Mortgage lending',
    amount: '730,470',
    currency: "P'000",
    performing: '676,367',
    nonPerforming: '52,384',
    provision: '1,719',
    coverageRatio: '7.4%',
    guarantee: '',
  },
  {
    sector: 'Corporate lending',
    amount: '305,802',
    currency: "P'000",
    performing: '295,802',
    nonPerforming: '5,820',
    provision: '4,179',
    coverageRatio: '3.3%',
    guarantee: '',
  },
  {
    sector: 'Retail and SME lending',
    amount: '5,784,336',
    currency: "P'000",
    performing: '5,463,129',
    nonPerforming: '86,685',
    provision: '234,522',
    coverageRatio: '5.6%',
    guarantee: '225,921',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const IMB2023Data = [
  {
    sector: 'CorporateLending',
    amount: '2,545,754',
    currency: "SR'000",
    performing: '2,477,534',
    nonPerforming: '68,220',
    provision: '25,983',
    coverageRatio: '3.7%',
    guarantee: '',
  },
  {
    sector: 'Retail Lending',
    amount: '294,012',
    currency: "SR'000",
    performing: '272,851',
    nonPerforming: '21,161',
    provision: '15,432',
    coverageRatio: '12.4%',
    guarantee: '',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const IMB2022Data = [
  {
    sector: 'CorporateLending',
    amount: '2,506,733',
    currency: "SR'000",
    performing: '2,506,733',
    nonPerforming: '-',
    provision: '16,226',
    coverageRatio: '0.6%',
    guarantee: '',
  },
  {
    sector: 'Retail Lending',
    amount: '236,795',
    currency: "SR'000",
    performing: '236,759',
    nonPerforming: '36',
    provision: '42',
    coverageRatio: '0.0%',
    guarantee: '',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const MCB2023Data = [
  {
    sector: 'Agriculture & Fishing',
    amount: '9,525',
    currency: "RS'M",
    performing: '7,264',
    nonPerforming: '926',
    provision: '1,335',
    coverageRatio: '23.7%',
    guarantee: '',
  },
  {
    sector: 'Manufacturing',
    amount: '17,253',
    currency: "RS'M",
    performing: '16,501',
    nonPerforming: '213',
    provision: '539',
    coverageRatio: '4.4%',
    guarantee: '',
  },
  {
    sector: 'Tourism',
    amount: '31,035',
    currency: "RS'M",
    performing: '27,752',
    nonPerforming: '795',
    provision: '2,488',
    coverageRatio: '10.6%',
    guarantee: '',
  },
  {
    sector: 'Transport',
    amount: '8,866',
    currency: "RS'M",
    performing: '8,078',
    nonPerforming: '269',
    provision: '519',
    coverageRatio: '8.9%',
    guarantee: '',
  },
  {
    sector: 'Construction',
    amount: '16,001',
    currency: "RS'M",
    performing: '15,310',
    nonPerforming: '276',
    provision: '415',
    coverageRatio: '4.3%',
    guarantee: '',
  },
  {
    sector: 'Financial and business services',
    amount: '50,205',
    currency: "RS'M",
    performing: '48,952',
    nonPerforming: '325',
    provision: '928',
    coverageRatio: '2.5%',
    guarantee: '',
  },
  {
    sector: 'Traders',
    amount: '121,468',
    currency: "RS'M",
    performing: '117,843',
    nonPerforming: '1,070',
    provision: '2,555',
    coverageRatio: '3.0%',
    guarantee: '',
  },
  {
    sector: 'Personal',
    amount: '51,138',
    currency: "RS'M",
    performing: '49,531',
    nonPerforming: '947',
    provision: '660',
    coverageRatio: '3.1%',
    guarantee: '',
  },
  {
    sector: 'Professional',
    amount: '375',
    currency: "RS'M",
    performing: '349',
    nonPerforming: '15',
    provision: '11',
    coverageRatio: '6.9%',
    guarantee: '',
  },
  {
    sector: 'Global Business License Holders',
    amount: '23,849',
    currency: "RS'M",
    performing: '9,862',
    nonPerforming: '7,285',
    provision: '6,702',
    coverageRatio: '58.6%',
    guarantee: '',
  },
  {
    sector: 'Others',
    amount: '38,050',
    currency: "RS'M",
    performing: '34,207',
    nonPerforming: '1,515',
    provision: '2,328',
    coverageRatio: '10.1%',
    guarantee: '',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];

const MCB2022Data = [
  {
    sector: 'Agriculture & Fishing',
    amount: '8,904',
    currency: "RS'M",
    performing: '7,146',
    nonPerforming: '903',
    provision: '855',
    coverageRatio: '19.7%',
    guarantee: '',
  },
  {
    sector: 'Manufacturing',
    amount: '22,923',
    currency: "RS'M",
    performing: '21,709',
    nonPerforming: '514',
    provision: '700',
    coverageRatio: '5.3%',
    guarantee: '',
  },
  {
    sector: 'Tourism',
    amount: '34,696',
    currency: "RS'M",
    performing: '31,617',
    nonPerforming: '452',
    provision: '2,627',
    coverageRatio: '8.9%',
    guarantee: '',
  },
  {
    sector: 'Transport',
    amount: '6,770',
    currency: "RS'M",
    performing: '5,932',
    nonPerforming: '287',
    provision: '551',
    coverageRatio: '12.4%',
    guarantee: '',
  },
  {
    sector: 'Construction',
    amount: '16,319',
    currency: "RS'M",
    performing: '14,869',
    nonPerforming: '759',
    provision: '691',
    coverageRatio: '8.9%',
    guarantee: '',
  },
  {
    sector: 'Financial and business services',
    amount: '29,594',
    currency: "RS'M",
    performing: '28,668',
    nonPerforming: '277',
    provision: '649',
    coverageRatio: '3.1%',
    guarantee: '',
  },
  {
    sector: 'Traders',
    amount: '112,343',
    currency: "RS'M",
    performing: '108,828',
    nonPerforming: '1,425',
    provision: '2,090',
    coverageRatio: '3.1%',
    guarantee: '',
  },
  {
    sector: 'Personal',
    amount: '46,414',
    currency: "RS'M",
    performing: '44,572',
    nonPerforming: '1,049',
    provision: '793',
    coverageRatio: '4.0%',
    guarantee: '',
  },
  {
    sector: 'Professional',
    amount: '1,335',
    currency: "RS'M",
    performing: '1,141',
    nonPerforming: '105',
    provision: '89',
    coverageRatio: '14.5%',
    guarantee: '',
  },
  {
    sector: 'Global Business License Holders',
    amount: '16,175',
    currency: "RS'M",
    performing: '5,514',
    nonPerforming: '7,414',
    provision: '3,247',
    coverageRatio: '65.9%',
    guarantee: '',
  },
  {
    sector: 'Others',
    amount: '44,091',
    currency: "RS'M",
    performing: '41,286',
    nonPerforming: '1,146',
    provision: '1,659',
    coverageRatio: '6.4%',
    guarantee: '',
  },
  {
    sector: 'Total',
    amount: '',
    currency: '',
    performing: '',
    nonPerforming: '',
    provision: '',
    coverageRatio: '',
    guarantee: '',
  },
];
const LET2023Data = [
  {
    sector: 'Total',
    amount: '4,783,462',
    currency: 'N$000',
    performing: '4,480,861',
    nonPerforming: '302,601',
    provision: '41,155',
    coverageRatio: '7.2%',
    guarantee: '',
  },
];

const LET2022Data = [
  {
    sector: 'Total',
    amount: '4,801,245',
    currency: 'N$000',
    performing: '4,564,018',
    nonPerforming: '237,227',
    provision: '48,543',
    coverageRatio: '6.0%',
    guarantee: '',
  },
];

const BreakdownOfLoans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [data2023, setData2023] = useState([]);
  const [data2024, setData2024] = useState([]);
  useEffect(() => {
    if (state?.name === 'FIRST NATIONAL BANK OF ESWATINI LIMITED') {
      setData2023(FBN2023Data);
      setData2024(FBN2022Data);
    } else if (state?.name === 'ABC HOLDINGS LIMITED') {
      setData2023(ABC2023Data);
      setData2024(ABC2022Data);
    } else if (
      state?.name ===
      'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ'
    ) {
      setData2023(IMB2023Data);
      setData2024(IMB2022Data);
    } else if (state?.name === 'LETSHOGO HOLDING') {
      setData2023(MCB2023Data);
      setData2024(MCB2022Data);
    } else if (
      state?.name === 'MAURITIUS COMMERCIAL BANK (MADAGASCAR) S.A. (THE)'
    ) {
      setData2023(LET2023Data);
      setData2024(LET2022Data);
    }
  }, [state]);

  return (
    <Grid container>
      <Stack>
        <Box display="flex" mt={2} gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Country :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedCountry?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedCountry?.displayName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Bank :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedBank?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedBank?.bankName}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2023
          </Typography>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2023.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2023?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" color="textPrimary">
            Breakdown of Loans
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            2022
          </Typography>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="breakdown of loans table">
            <TableHead>
              <TableRow>
                <HeadTableCell>Sector</HeadTableCell>
                <HeadTableCell align="right">Amount</HeadTableCell>
                <HeadTableCell align="center">Currency</HeadTableCell>
                <HeadTableCell align="right">Performing</HeadTableCell>
                <HeadTableCell align="right">Non Performing</HeadTableCell>
                <HeadTableCell align="right">Provision</HeadTableCell>
                <HeadTableCell align="right">Coverage Ratio</HeadTableCell>
                <HeadTableCell align="right">Guarantee</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data2024.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background:
                      index === data2024?.length - 1 ? '#243842' : '#001823',
                  }}
                >
                  <StyledTableCell>{row.sector}</StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.currency}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.performing}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.nonPerforming}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.provision}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.coverageRatio}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.guarantee}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* Buttons */}
      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-result', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() =>
                navigate('/financial-Board-director', {
                  state: location.state,
                })
              }
            >
              Next
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BreakdownOfLoans;
