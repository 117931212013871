import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    Tooltip,
  } from "@mui/material";
  import React from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import AnimateButton from "../../components/common/formStyle/AnimateButton";
  import InfoIcon from '@mui/icons-material/Info';
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  
  // Styled table cell components
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid #000",
    fontSize: "0.9rem",
  }));
  
  const HeadTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid #000",
    backgroundColor: "#132933",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "1rem",
    width: "50%",
  }));
  
  const SectionHeaderCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#132933",
    fontWeight: "bold",
    color: theme.palette.common.white,
    textAlign: "center",
    borderBottom: "none",
    width: "50%",
  }));
  
  
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'grey',
      color: 'black',
      fontSize: '0.900rem',
    },
  });
  // Data for Board of Directors
  const CashFlowData = [
    {
      "name": "Operating Cash Flow (OCF)",
      "definition": "Measures cash generated from core business activities, reflecting the cash available after covering operating expenses.",
      "importance": "Shows if a business can sustain its day-to-day operations with its current cash inflows.",
      "calculation": "Cash received from customers – Cash paid for operating expenses",
      "amount": 1000 
    },
    {
      "name": "Free Cash Flow (FCF)",
      "definition": "The cash left over after covering operating expenses and capital expenditures.",
      "importance": "Indicates the cash available for expansion, debt repayment, or distributions to owners. Positive FCF is a good sign of financial health.",
      "calculation": "Operating Cash Flow – Capital Expenditures",
      "amount": 20000
    },
    {
      "name": "Cash Flow Forecasting",
      "definition": "A projection of cash inflows and outflows over a specific period (weekly, monthly, or quarterly).",
      "importance": "Helps anticipate cash needs and avoid cash shortages by understanding future cash positions.",
      "tools": "Use historical cash flow data, sales forecasts, and expense projections to create the forecast",
      "amount": 5000
    },
    {
      "name": "Cash Burn Rate",
      "definition": "The rate at which a business uses up cash reserves to fund operations, often measured monthly.",
      "importance": "Critical for startups or businesses with negative cash flow to determine how long cash reserves will last.",
      "calculation": "Total Cash Outflows per Month",
      "amount": 6000 
    },
    {
      "name": "Cash Flow Margin",
      "definition": "The percentage of cash flow relative to revenue, showing how much cash is generated from each dollar of sales.",
      "importance": "A high cash flow margin means the business generates a strong cash flow relative to sales, indicating good operational efficiency.",
      "calculation": "(Operating Cash Flow / Total Revenue) × 100",
      "amount": 4000 
    },
    {
      "name": "Accounts Receivable Turnover",
      "definition": "Measures how quickly the business collects cash from credit sales.",
      "importance": "Faster collection improves cash flow, while slower collection can create cash flow issues.",
      "calculation": "Net Credit Sales / Average Accounts Receivable",
      "amount": 8000
    },
    {
      "name": "Days Sales Outstanding (DSO)",
      "definition": "The average number of days it takes to collect payment after a sale.",
      "importance": "Lower DSO improves cash flow and indicates efficient credit management.",
      "calculation": "(Accounts Receivable / Total Credit Sales) × Number of Days",
      "amount": 4000
    },
    {
      "name": "Accounts Payable Turnover",
      "definition": "Measures how quickly a business pays its suppliers.",
      "importance": "A low turnover ratio can mean the business has cash flow issues, while a high ratio indicates timely payments, often with better supplier relationships.",
      "calculation": "Total Purchases / Average Accounts Payable",
      "amount": 1000 
    },
    {
      "name": "Current Ratio",
      "definition": "Measures a business’s ability to meet short-term obligations using its current assets.",
      "importance": "A ratio of 1 or higher is typically healthy, indicating that current assets exceed current liabilities.",
      "calculation": "Current Assets / Current Liabilities",
      "amount": 6000 
    },
    {
      "name": "Debt Service Coverage Ratio (DSCR)",
      "definition": "Measures the ability to cover debt obligations with cash flow.",
      "importance": "A DSCR above 1 means the business generates enough cash flow to cover its debt, reducing the risk of default.",
      "calculation": "Operating Cash Flow / Total Debt Service",
      "amount": 4000 
    }
  ];
  
  const CashFlowMatrixTable = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    return (
      <Grid container spacing={2} mt={5}>
        <Stack>
          <Box display="flex" mt={2} gap={2} ml={3}>
            <Typography variant="h4" color="orange.main"  >
              Cash Flow Matrix
            </Typography>
          </Box>
        </Stack>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "12px", overflow: "hidden", mb: 4 }}
          >
            <Table aria-label="cash flow table">
              <TableHead>
                <TableRow>
                  <SectionHeaderCell>Description</SectionHeaderCell>
                  <SectionHeaderCell>Amount</SectionHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CashFlowData.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{row.name}<CustomTooltip
                      title={row?.definition}
                      sx={{ color: '#000000' }}
                      arrow
                    >
                      <InfoIcon
                        style={{
                          marginLeft: '8px',
                          fontSize: '1.2rem',
                          cursor: 'pointer',
                        }}
                      />
                    </CustomTooltip></StyledTableCell>
                    
                    <StyledTableCell style={{ textAlign: 'center' }}>{row.amount}
                    <CustomTooltip
                      title={row?.importance}
                      sx={{ color: '#000000' }}
                      arrow
                    >
                      <InfoIcon
                        style={{
                          marginLeft: '8px',
                          fontSize: '1.2rem',
                          cursor: 'pointer',
                        }}
                      />
                    </CustomTooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
  
        <Grid item xs={12} my={4} sx={{ width: "100%" }}>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={3}
          >
            <AnimateButton>
              <Button
                variant="outlined"
                size="large"
                startIcon={<ArrowBackIcon />}
                onClick={() =>
                  navigate("/cash-flow-analysis")
                }
              >
                Prev
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button variant="contained" size="large">
                Done
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    );
  };
  
  export default CashFlowMatrixTable;