/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import {
  ContentStyle,
  RootStyle,
  FormStyle,
  FromWrapper,
  FormInfoBox,
  FormInputWrapper,
} from "./AuthStyle";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import InputLabel from "../../components/common/formStyle/InputLabel";
import PasswordStrengthMeter from "./PassWordStrenth";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import { toast } from "react-toastify";
import GetAxiosInstance from "../../components/common/api";
import { LoadingButton } from "@mui/lab";

export function PasswordSetup() {
  const [password, setPassword] = useState("");
  const [Strength, setStrength] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conPassWord, setConPassWord] = useState("");
  const [conPassWordError, setConPassWordError] = useState("");
  const [loading, setLoading] = useState();
  const theme = useTheme();

  const dataHandler = async (childData) => {
    setStrength(childData);
  };

  let navigate = useNavigate();
  let { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/signup");
    }
  }, []);

  const onSubmitFuc = (e) => {
    if (!password) {
      setPasswordError("Please Enter Password");
      return;
    }
    if (!conPassWord) {
      setConPassWordError("Please Enter Confirm Password");
      return;
    } else if (password !== conPassWord) {
      setConPassWordError("Password must match");
      return;
    }
    if (password.length < 8) {
      toast.error("Password must be 8 characters");
      return;
    }

    let capsCount, smallCount, numberCount, symbolCount;
    capsCount = (password.match(/[A-Z]/g) || []).length;
    smallCount = (password.match(/[a-z]/g) || []).length;
    numberCount = (password.match(/[0-9]/g) || []).length;
    symbolCount = (password.match(/\W/g) || []).length;

    if (capsCount === 0 || smallCount === 0 || numberCount === 0 || symbolCount === 0) {
      toast.error("Password must contain at least 8 characters, including uppercase, lowercase, number, and special character.");
      return false;
  }

    state["password"] = password;

    if (state?.flow === "resetPassword") {
      setLoading(true);
      GetAxiosInstance(`auth/resetPassword`, "post", state).then((res) => {
        setLoading(false);
        if (res?.data?.codes === 200) {
          toast.success(res?.data?.message);
          navigate("/login");
        } else {
          toast.error(res?.response?.data?.message || res.message);
        }
      });
    } else {
      navigate("/account-setup", { state: state });
    }
  };

  const handleChange = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  return (
    <RootStyle>
      <FromWrapper>
        <ContentStyle>
          <FormStyle
            component="form"
            autoComplete="off"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <FormInfoBox>
                <Typography variant="Body_semibold_24">
                  Password Setup
                </Typography>
              </FormInfoBox>

              <FormInputWrapper>
                <Grid item xs={12}>
                  <InputLabel>Create Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="Password 8+ characters"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      setPasswordError("");
                    }}
                    inputProps={{ 'aria-label': 'Without label  ' }}
                    hiddenLabel
                    helperText={passwordError}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Confirm Password</InputLabel>
                  <TextField
                    variant="filled"
                    type="password"
                    placeholder="Re-enter password"
                    id="filled-hidden-label-normal"
                    fullWidth
                    onChange={(e) => {
                      setConPassWord(e.target.value);
                      setConPassWordError('');
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    hiddenLabel
                    helperText={conPassWordError}
                  />
                </Grid>
              </FormInputWrapper>

              <Grid item xs={12} mb={4}>
                <PasswordStrengthMeter
                  password={password}
                  actions={dataHandler}
                />
              </Grid>

              <AnimateButton>
                <LoadingButton
                  size="large"
                  type="button"
                  variant="contained"
                  style={{ width: '100%' }}
                  onClick={onSubmitFuc}
                  loadingIndicator={
                    <CircularProgress
                      style={{
                        padding: '60px',
                        color: theme.palette.orange.main,
                      }}
                    />
                  }
                  loading={loading}
                >
                  Proceed
                </LoadingButton>
              </AnimateButton>
            </Stack>
          </FormStyle>
        </ContentStyle>
      </FromWrapper>
    </RootStyle>
  );
}