import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from 'react-router-dom';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
  background: '#243842',
}));

const summaryRows = [
  {
    section: 'Capitalization',
    data: [
      {
        covenant: 'Capital Adequacy Ratio (CAR)',
        minCriteria: '> 8% (Basel II) but preferably > 10.5% (Basel III)',
      },
      {
        covenant: 'Lock-up-ratio: (Fixed assets + participations ) / Equity',
        minCriteria: '< 75%',
      },
      { covenant: 'Tier 1 / Total Capital', minCriteria: '< 70%' },
    ],
  },
  {
    section: 'Asset Quality',
    data: [
      {
        covenant:
          'Open exposure ratio: (loans classified C,D,E minus loan loss reserves)',
        minCriteria: '< 50% of equity but preferably < 25% of equity',
      },
      { covenant: 'Group client ratio', minCriteria: '< 30% of equity' },
      { covenant: 'Single client ratio', minCriteria: '< 20% of equity' },
      { covenant: 'Aggregate Exposure ratio', minCriteria: '< 20%' },
      {
        covenant: 'Government assets ratio',
        minCriteria: '< 20% of Total Assets',
      },
    ],
  },
  {
    section: 'Profitability',
    data: [
      { covenant: 'Net Interest Margin (NIM)M', minCriteria: '>2%' },
      { covenant: 'Overhead ratio (cost to income)', minCriteria: '<70%' },
    ],
  },
  {
    section: 'Liquidity',
    data: [
      {
        covenant: '3 month and 1 month maturity gap',
        minCriteria: '<100% of Equity',
      },
      {
        covenant: 'Aggregate Unhedged open currency position',
        minCriteria: '<25% of Equity',
      },
      {
        covenant: 'Interbank short term funding concentration',
        minCriteria: '< 20% of total funding (excluding equity)',
      },
    ],
  },
];

const Summary = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grid container>
      <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="Body_semibold_20">Summary</Typography>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table
            sx={{ minWidth: 650, width: '100%' }}
            aria-label="summary table"
          >
            <TableHead>
              <TableRow>
                <HeadTableCell align="left">
                  Overview Financial Covenants
                </HeadTableCell>
                <HeadTableCell align="center">Minimum Criteria</HeadTableCell>
                <HeadTableCell align="center">
                  Mini. Criteria of Country central bank
                </HeadTableCell>
                <HeadTableCell align="center">
                  Actual Ratio Financial Institution
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: '#001823' }}>
              {summaryRows.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  {/* Section Title */}
                  <TableRow>
                    <StyledTableCell colSpan={4} style={{ fontWeight: 'bold' }}>
                      {section.section}
                    </StyledTableCell>
                  </TableRow>
                  {/* Data Rows */}
                  {section.data.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <StyledTableCell>{row.covenant}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.minCriteria}
                      </StyledTableCell>
                      <StyledTableCell align="right">------</StyledTableCell>
                      <StyledTableCell align="right">------</StyledTableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* Buttons */}
      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-result', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate('/financial-summary')}
            >
              Done
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>{' '}
    </Grid>
  );
};

export default Summary;
