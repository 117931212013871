import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/Logo.svg';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import AnimateButton from '../../common/formStyle/AnimateButton';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 100;
const APPBAR_DESKTOP = 93;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  minHeight: APPBAR_MOBILE,
  zIndex: 99,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
  },

  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const BoxWrap = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  padding: theme.spacing(2, 5),
}));

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SearchTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '10px',
}));

export const SearchBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(6),
}));

// ----------------------------------------------------------------------

FinancialAnalysisNav.propTypes = {
  onOpenDashboardSidebar: PropTypes.func,
};

export default function FinancialAnalysisNav({ onOpenDashboardSidebar }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const isActiveRoot = useLocation();

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);
  const handleLogOut = () => {
    navigate('/external-login');
    localStorage.removeItem('isExternalLogin');
  };

  const displayDesktop = () => {
    return (
      <BoxWrap sx={{}}>
        <MainStyle>
          <Box
            sx={{
              minWidth: 300,
            }}
          >
            <Box
              component="img"
              src={Logo}
              alt="Logo"
              width={144}
              height={58}
            />
          </Box>

          {/* Search */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={'space-between'}
            gap={3}
            sx={{
              flex: 1,
            }}
          >
            <Stack direction={'row'} gap={4}>
              <Divider
                sx={{
                  borderColor: 'white',
                }}
                orientation="vertical"
                flexItem
              />
              <Box>
                <SearchTextField
                  // variant="standard"
                  autoFill="false"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: theme.palette.common.white }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                  // onChange={handleSearch}
                  // value={search}
                />
              </Box>
            </Stack>
            {/* <AnimateButton>
              <Button variant="contained" size="large" onClick={handleLogOut}>
                Sign out
              </Button>
            </AnimateButton> */}
            {/* <Button>
              <Typography color="common.white">Sign out</Typography>
            </Button> */}
            {/* <MyAccount /> */}
          </Stack>
        </MainStyle>
      </BoxWrap>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box component="img" src={Logo} alt="Logo" width={94} height={41} />

          <Stack direction="row" alignItems="center">
            {isActiveRoot.pathname === '/dashboard' && (
              <IconButton
                onClick={onOpenDashboardSidebar}
                sx={{
                  display: { lg: 'none' },
                }}
              >
                <KeyboardArrowDownIcon
                  sx={{ fill: theme.palette.orange.main }}
                  fontSize="large"
                />
              </IconButton>
            )}

            <IconButton
              {...{
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
        </Stack>

        <Dialog
          {...{
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          PaperProps={{
            sx: { width: '100%' },
          }}
        >
          {/* <Stack direction="column" alignItems="center" gap={3}>
            <Box
              py={4}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid #002E45',
              }}
            >
              <MyAccount />
            </Box>
          </Stack> */}
        </Dialog>
      </>
    );
  };

  return (
    <>
      <RootStyle>
        <>{mobileView ? displayMobile() : displayDesktop()}</>
      </RootStyle>
    </>
  );
}
