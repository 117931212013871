import { styled } from "@mui/material/styles";
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import FinancialAnalysisNav from '../Navbar/FinancialAnalysisNav';
import CashFlowAnalysisNav from '../Navbar/CashFlowAnalysisNav';
// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  width: '100%',

  padding: theme.spacing(8, 3, 0),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(12, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export function CashFlowAnalysisLayout() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      
    }, 200);
  }, []);
  return (
    <>
      <RootStyle>
        <CashFlowAnalysisNav />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
