import { Box, Typography } from '@mui/material';
import React from 'react';
import './passwordStrenth.css';

const PasswordStrengthMeter = (props) => {
  const testedResult = props.password;

  const createPasswordLabel = () => {
    let score = 0;
    let regexPositive = [
      '.{8,}', // Minimum length 8
      '(?=.*[A-Z])', // At least one uppercase letter
      '(?=.*[a-z])', // At least one lowercase letter
      '(?=.*[0-9])', // At least one number
      '(?=.*[!@#$%^&*])' // At least one special character
    ];

    regexPositive.forEach((regex) => {
      if (new RegExp(regex).test(testedResult)) {
        score += 1;
      }
    });

    switch (score) {
      case 0:
      case 1:
      case 2:
        return {
          value: 1,
          info: 'Weak',
        };
      case 3:
      case 4:
        return {
          value: 2,
          info: 'Medium',
        };
      case 5:
        return {
          value: 3,
          info: 'Strong',
        };
      default:
        return null;
    }
  };

  // eslint-disable-next-line no-lone-blocks
  {
    props.actions(createPasswordLabel().info);
  }

  return (
    <>
      <Box component={'div'} className="password-strength-meter">
        <progress
          className={`password-strength-meter-progress strength-${
            createPasswordLabel().info
          }`}
          value={createPasswordLabel().value}
          max="3"
        />
        <br />
        <Typography
          variant="Body_light_12"
          my={1}
          className="password-strength-meter-label"
        >
          Password strength: {createPasswordLabel().info}
        </Typography>
      </Box>
    </>
  );
};

export default PasswordStrengthMeter;