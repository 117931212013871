import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#132933',
    color: '#ffffff',
    fontSize: '0.875rem',
  },
});

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));
const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#FB5718', // Thumb color when checked
    '&:hover': {
      backgroundColor: 'rgba(251, 87, 24, 0.08)', // Light hover effect when checked
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#FB5718', // Track color when checked
  },
}));

const FiResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(
    location.state?.selectedData || []
  );
  const [isCalculationExpanded, setCalculationExpanded] = useState(false); // State for toggle
  const [isResultExpanded, setResultExpanded] = useState(false); // State for Result toggle

  // Function to format numbers with commas for display
  const formatNumberWithCommas = (value) => {
    const num = Number(String(value).replace(/,/g, ''));
    if (!isNaN(num)) {
      return num.toLocaleString('en-US');
    }
    return value;
  };

  const toggleCalculation = () => {
    setCalculationExpanded((prev) => !prev);
  };
  const toggleResult = () => {
    setResultExpanded((prev) => !prev);
  };

  return (
    <>
      <Grid container>
        {/* Page 1 table */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
              padding: '16px',
              borderRadius: '8px',
            }}
          >
            <Stack>
              <Box display="flex" mt={2} mb={2} gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography> Country :- </Typography>
                  <Box
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'50%'}
                    component={'img'}
                    src={location.state?.selectedCountry?.icon}
                    sx={{ border: '1px solid #ccc' }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {location.state?.selectedCountry?.displayName}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography> Bank :- </Typography>
                  <Box
                    height={'40px'}
                    width={'40px'}
                    borderRadius={'50%'}
                    component={'img'}
                    src={location.state?.selectedBank?.icon}
                    sx={{ border: '1px solid #ccc' }}
                  />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {location.state?.selectedBank?.bankName}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Input
              </Typography>
            </Stack>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={isResultExpanded}
                  onChange={toggleResult}
                />
              }
              label="External Sources"
              sx={{ marginLeft: '16px' }}
            />
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '100px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {selectedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell
                      align="center"
                      sx={{ width: '100px', padding: '0px 20px !important' }}
                    >
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ width: '200px', padding: '0px 20px !important' }}
                    >
                      {row.label}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ width: '150px', padding: '0px 20px !important' }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ padding: '20px 12px' }}>
                          {row.y1 && formatNumberWithCommas(row.y1)}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{ width: '150px', padding: '0px 20px !important' }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ padding: '20px 12px' }}>
                          {row.y2 && formatNumberWithCommas(row.y2)}
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Result table */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body_semibold_20">Result</Typography>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '100px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {selectedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell align="center" sx={{ width: '100px' }}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {index === 0 ? (
                        <>
                          {row.resultLabel}{' '}
                          <CustomTooltip
                            title={row?.tooltip}
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                          <Stack>
                            ({location.state?.selectedCountry?.displayName}{' '}
                            {row.resultLabel} Limit:- {row?.limit}%)
                          </Stack>
                        </>
                      ) : (
                        <>
                          {row.resultLabel}{' '}
                          <CustomTooltip
                            title={row?.tooltip}
                            sx={{ color: '#000000' }}
                            arrow
                          >
                            <InfoIcon
                              style={{
                                marginLeft: '8px',
                                fontSize: '1.2rem',
                                cursor: 'pointer',
                              }}
                            />
                          </CustomTooltip>
                        </>
                      )}
                    </StyledTableCell>

                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color:
                          index === 0 && isResultExpanded
                            ? parseInt(row?.y7.split('%')[0])
                            : parseInt(row?.y3.split('%')[0]) > row?.limit
                            ? 'green'
                            : index === 0
                            ? 'red'
                            : 'inherit',
                      }}
                    >
                      {isResultExpanded
                        ? row?.y7 && formatNumberWithCommas(row.y7)
                        : row?.y3 && formatNumberWithCommas(row.y3)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        width: '185px',
                        padding: '0px 20px !important',
                        color:
                          index === 0 && isResultExpanded
                            ? parseInt(row?.y8.split('%')[0])
                            : parseInt(row?.y4.split('%')[0]) > row?.limit
                            ? 'green'
                            : index === 0
                            ? 'red'
                            : 'inherit',
                      }}
                    >
                      {isResultExpanded
                        ? row?.y8 && formatNumberWithCommas(row.y8)
                        : row?.y4 && formatNumberWithCommas(row.y4)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* Calculation table with expand/collapse functionality */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <Box
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body_semibold_20">Calculation</Typography>
            <Button
              variant="text"
              onClick={toggleCalculation}
              startIcon={
                isCalculationExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
              }
            >
              {isCalculationExpanded ? 'Collapse' : 'Expand'}
            </Button>
          </Box>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center" sx={{ width: '100px' }}>
                    #
                  </HeadTableCell>
                  <HeadTableCell sx={{ width: '200px' }}>
                    In {location?.state?.currencyName} '000
                  </HeadTableCell>
                  {location.state?.header?.length &&
                    location.state?.header.map((item, idx) => (
                      <HeadTableCell
                        key={idx}
                        align="right"
                        sx={{ width: '150px' }}
                      >
                        YE {item}
                      </HeadTableCell>
                    ))}
                </TableRow>
              </TableHead>
              {isCalculationExpanded && ( // Conditional rendering of TableBody
                <TableBody
                  sx={{
                    background: '#001823',
                  }}
                >
                  {selectedData.map((row, index) => (
                    <>
                      {row.calculationLabel && (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              borderBottom: 0,
                            },
                          }}
                        >
                          <StyledTableCell
                            align="center"
                            sx={{ width: '100px' }}
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{
                              width: '200px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row.calculationLabel}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y5 && formatNumberWithCommas(row?.y5)}
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            sx={{
                              width: '185px',
                              padding: '0px 20px !important',
                            }}
                          >
                            {row?.y6 && formatNumberWithCommas(row?.y6)}
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                  {location.state?.calculation?.y5 && (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell align="center" sx={{ width: '100px' }}>
                        {selectedData.length + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ width: '200px', padding: '0px 20px !important' }}
                      >
                        Total Capital (shareholders equity)
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        sx={{ width: '185px', padding: '0px 20px !important' }}
                      >
                        {formatNumberWithCommas(
                          location.state?.calculation?.y5
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        sx={{ width: '185px', padding: '0px 20px !important' }}
                      >
                        {formatNumberWithCommas(
                          location.state?.calculation?.y6
                        )}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>

        {/* Buttons */}
        <Grid item xs={12} my={4} mb={20} sx={{ width: '100%' }}>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={3}
          >
            <AnimateButton>
              <Button
                variant="outlined"
                size="large"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate('/financial-institution')}
              >
                Prev
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={() =>
                  navigate('/financial-loan-breakdown', {
                    state: location.state,
                  })
                }
              >
                Next
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default FiResult;
