import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AnimateButton from "../../components/common/formStyle/AnimateButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Styled table cell components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #000",
  fontSize: "0.9rem",
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #000",
  backgroundColor: "#132933",
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "1rem",
  width: "50%",
}));

const SectionHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#132933",
  fontWeight: "bold",
  color: theme.palette.common.white,
  textAlign: "center",
  borderBottom: "none",
  width: "50%",
}));

const CategoryCell = styled(StyledTableCell)`
  font-size: 1.5rem; /* Increase font size */
`;

const IndexHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#132933",
  fontWeight: "bold",
  color: theme.palette.common.white,
  textAlign: "center",
  borderBottom: "none",
  width: "3%",
  textAlign: "center",
}));

// Data for Board of Directors
const FNBboardData = [
  {
    Category: "Operating Activities",
    SubCategory: "Cash Receipts from Sales",
    Amount: "50,000",
  },
  {
    Category: "Operating Activities",
    SubCategory: "Cash Paid for Inventory and Supplies",
    Amount: "20,000",
  },
  {
    Category: "Operating Activities",
    SubCategory: "Cash Paid for Operating Expenses",
    Amount: "15,000",
  },
  {
    Category: "Operating Activities",
    SubCategory: "Cash Paid for Salaries",
    Amount: "8,000",
  },
  {
    Category: "Net Cash Flow from Operating Activities",
    CategoryAmount: "7,000",
  },
  {
    Category: "Investing Activities",
    SubCategory: "Purchase of Equipment",
    Amount: "5,000",
  },
  {
    Category: "Investing Activities",
    SubCategory: "Cash Receipts from Sale of Equipment",
    Amount: "00",
  },
  {
    Category: "Net Cash Flow from Investing Activities",
    CategoryAmount: "5,000",
  },
  {
    Category: "Financing Activities",
    SubCategory: "New Loan Taken",
    Amount: "10,000",
  },
  {
    Category: "Financing Activities",
    SubCategory: "Loan Repayment",
    Amount: "2,000",
  },
  {
    Category: "Financing Activities",
    SubCategory: "Owner's Drawings",
    Amount: "1,000",
  },
  {
    Category: "Net Cash Flow from Financing Activities",
    CategoryAmount: "7,000",
  },
  {
    Category: "Net Increase in Cash",
    CategoryAmount: "9,000",
  },
  {
    Category: "Cash at Beginning of Period",
    CategoryAmount: "2,000",
  },
  {
    Category: "Cash at End of Period",
    CategoryAmount: "11,000",
  },
];

const CashFlowAnalysisTable = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Group data by category
  const groupedData = FNBboardData.reduce((acc, item) => {
    if (!acc[item.Category]) {
      acc[item.Category] = [];
    }
    acc[item.Category].push(item);
    return acc;
  }, {});

  return (
    <Grid container spacing={2} mt={5}>
      <Stack>
        <Box display="flex" mt={2} gap={2} ml={3}>
          <Typography variant="h4" color="orange.main"  >
            Cash Flow Analysis
          </Typography>
        </Box>
      </Stack>
      {/* Board of Directors Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "12px", overflow: "hidden", mb: 4 }}
        >
          <Table aria-label="board of directors table">
            <TableHead>
              <TableRow>
                <IndexHeaderCell></IndexHeaderCell>
                <SectionHeaderCell>SubCategory</SectionHeaderCell>
                <SectionHeaderCell>Amount</SectionHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedData).map((category, index) => {
                const CategoryAmount = groupedData[category].find(
                  (item) => item.CategoryAmount
                )?.CategoryAmount;
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <StyledTableCell>#</StyledTableCell>
                      <CategoryCell>{category}</CategoryCell>
                      <StyledTableCell style={{ textAlign: 'center' }} >{CategoryAmount}</StyledTableCell>
                    </TableRow>
                    {groupedData[category].map((row, subIndex) => (
                      <TableRow key={subIndex}>
                        {row.SubCategory && (
                          <StyledTableCell>{subIndex + 1}</StyledTableCell>
                        )}
                        <StyledTableCell>{row.SubCategory}</StyledTableCell>
                        <StyledTableCell style={{ textAlign: 'center' }}>{row.Amount}</StyledTableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} my={4} sx={{ width: "100%" }}>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={3}
        >
          <AnimateButton>
            {/* <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate("/financial-loan-breakdown", { state: location.state })
              }
            >
              Prev
            </Button> */}
          </AnimateButton>
          <AnimateButton>
          <Button
             variant="outlined"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() =>
                navigate('/cash-flow-matrix')
              }
            >
              Next
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CashFlowAnalysisTable;
