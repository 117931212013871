import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Styled table cell components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #000',
  fontSize: '0.9rem',
}));

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #000',
  backgroundColor: '#132933',
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const SectionHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#132933',
  fontWeight: 'bold',
  color: theme.palette.common.white,
  textAlign: 'center',
  borderBottom: 'none',
}));

const InstructionCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#132933',
  fontSize: '0.85rem',
  textAlign: 'center',
  color: theme.palette.common.white,
  fontStyle: 'italic',
}));

// Data for Board of Directors
const FNBboardData = [
  {
    name: 'J V Ndlangamandla',
    position: 'Board Chairperson, Independent Non-Executive Director',
    memberSince: '3-Feb-16',
    previousPosition: '',
    education: '',
  },
  {
    name: 'D T Mbingo',
    position: 'Chief Executive Officer',
    memberSince: '6-Feb-15',
    previousPosition: '',
    education: '',
  },
  {
    name: 'S de Sousa',
    position:
      'Member and Chair (Independent Loan Review Committee), Independent Non-Executive Director',
    memberSince: '5-Feb-10',
    previousPosition: '',
    education: '',
  },
  {
    name: 'D E Wright',
    position:
      'Member and Chair (Risk, Capital and Compliance Committee), Independent Non-Executive Director',
    memberSince: '3-Feb-16',
    previousPosition: '',
    education: '',
  },
  {
    name: 'S L Balsdon',
    position: 'Member, Non-Executive Director',
    memberSince: '21-Apr-17',
    previousPosition: '',
    education: '',
  },
  {
    name: 'E B Arden',
    position:
      'Member and Chair (Audit Committee), Independent Non-Executive Director',
    memberSince: '24-Apr-19',
    previousPosition: '',
    education: '',
  },
  {
    name: 'L J Haynes',
    position: 'Member, Non-Executive Director',
    memberSince: '11-Nov-21',
    previousPosition: '',
    education: '',
  },
  {
    name: 'J M Gule',
    position:
      'Member and Chair (Remuneration Committee), Independent Non-Executive Director',
    memberSince: '11-Feb-21',
    previousPosition: '',
    education: '',
  },
];

// Data for Executive Management
const FNBexecutiveData = [
  {
    name: 'No details about executive management',
    position: '',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: '',
    position: '',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const ABCboardData = [
  {
    name: 'Lerato Nthamo Moselhyane',
    position: 'Board Chairperson, Independent Non-Executive Director',
    memberSince: '23-Jul-14',
    previousPosition:
      'Over 17 years in various roles in different industries, former CFO at a leading Life Insurance Company',
    education:
      'Chartered Accountant (ACCA), MBA from Oxford Brookes University',
  },
  {
    name: 'Shepard Aisam',
    position: 'Managing Director',
    memberSince: '4-Jan-24',
    previousPosition:
      'Group Head of Public Sector, Business & Commercial Banking at Standard Bank Group',
    education:
      'BBA from University of Botswana, MSc in Strategic Management from University of Derby',
  },
  {
    name: 'Jacob Mooketsi Mathibane',
    position: 'Independent Non-Executive Director',
    memberSince: '12-Nov-14',
    previousPosition:
      'Former CEO of Tumstar Holdings, Group Strategy & Business Development Manager for Letshego Holdings Limited',
    education: 'Bachelor of Commerce in Accounting, Pursuing MBA at Mancosa',
  },
  {
    name: 'Joshua Benjamin Galeforolwe',
    position: 'Senior Independent Non-Executive Director',
    memberSince: '1-Apr-18',
    previousPosition:
      'Managing Consultant at West Cliff Capital, Former Chief Executive of Public Enterprises Evaluation and Privatisation Agency (PEEPA)',
    education: 'BCom in Accounting, BA in Economics',
  },
  {
    name: 'Boiki Matema Wabo Tema',
    position: 'Independent Non-Executive Director',
    memberSince: '3-Oct-18',
    previousPosition:
      '24 years of banking experience, including Deputy Director and Coverage Director at Rand Merchant Bank Botswana and former positions at First National Bank Botswana',
    education:
      'BA in Economics, MSc in Strategic Management from University of Derby',
  },
  {
    name: 'Noni Moselhe',
    position: 'Independent Non-Executive Director',
    memberSince: '1-Nov-18',
    previousPosition: 'Deputy CEO at Botswana Housing Corporation (BHC)',
    education: 'Bachelor of Administration',
  },
  {
    name: 'John Boso Sebabi',
    position: 'Independent Non-Executive Director',
    memberSince: '3-Jun-20',
    previousPosition:
      'Deputy Director General of Namibia Social Security Board, Central Bank of Zambia',
    education:
      'MSc in International Economics, Banking and Finance from Cardiff University',
  },
  {
    name: 'Lynda Matika',
    position: 'Independent Non-Executive Director',
    memberSince: '1-Jul-22',
    previousPosition: 'Legal roles, Board member at Access Bank Botswana',
    education:
      "LLB, Postgraduate Diploma in Legal Drafting, Master's in International Business Law",
  },
  {
    name: 'Oluseyi Kumapayi',
    position: 'Non-Executive Director',
    memberSince: '5-Nov-21',
    previousPosition:
      'Director of African Subsidiaries at Access Bank PLC, Former Group CFO at Access Bank PLC',
    education:
      "Master's in Mechanical Engineering, Bachelor's in Agricultural Engineering, Harvard Business School alumnus",
  },
  {
    name: 'Robert Michael Yowerth Giles',
    position: 'Non-Executive Director',
    memberSince: '5-Nov-21',
    previousPosition:
      'Senior Banking Advisor at Access Bank PLC, Former roles at Diamond Bank PLC and Access Bank PLC',
    education:
      'BSc in Geography, Postgraduate Diploma in Management from University of Leicester Business School',
  },
  {
    name: 'Ratang Isao-Mohalatsi',
    position: 'Finance Director',
    memberSince: '1-Sep-19',
    previousPosition:
      'Various roles at Mutual Botswana, Botswana Savings Bank, and Deloitte',
    education:
      'BSc in Economics and Accounting, ACCA, MSc in Strategic Management',
  },
];

// Data for Executive Management
const ABCexecutiveData = [
  {
    name: 'Muzonda Chishimba',
    position: 'Deputy Managing Director',
    startDate: '1-Oct-22',
    previousPosition:
      'Executive Director - Access Bank PLC, 25 years of banking experience including Citibank',
    education:
      'MBA in Finance from Heriot Watt Business School, BSc Honours in Computing',
  },
  {
    name: 'Godwin Chakwunta',
    position: 'Chief Operations Officer',
    startDate: '',
    previousPosition:
      'Over 20 years in banking roles at Standard Bank of Africa PLC and Zenith Bank PLC',
    education:
      'BSc in Mathematics and Statistics, MBA from Ahmadu Bello University, MSc in Finance and Management from Cranfield University',
  },
  {
    name: 'Thato Mmie',
    position: 'Head, Legal and Company Secretary',
    startDate: '',
    previousPosition:
      'Over 20 years in legal and corporate governance, former roles at ABC Holdings Limited and Standard Chartered Bank',
    education: 'LLB from University of Botswana',
  },
  {
    name: 'Pauline Modisegae',
    position: 'Head of Wholesale Banking',
    startDate: '',
    previousPosition:
      'Over 30 years banking experience, former roles at RMB Botswana, FNBB, Botswana Bank of Botswana',
    education:
      'MBA from University of Stellenbosch, Bachelor of Economics and Accounting',
  },
  {
    name: 'Segametsi Sebantabo',
    position: 'Chief Risk Officer',
    startDate: '',
    previousPosition:
      'Former roles in Barclays Bank and Standard Bank including Head of Credit Origination',
    education:
      "Master's in Leadership and Change Management from Leeds Metropolitan University, BA Honours in Economics and Environmental Science",
  },
];
const IMBboardData = [
  {
    name: 'Jennifer Morel',
    position: 'Director and Chairperson',
    memberSince: 'since August 2022',
    otherPositions: '',
    education: '',
  },
  {
    name: 'David Howes',
    position: 'Director',
    memberSince: 'Director since January 2018, reappointed July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Michael Bluemer',
    position: 'Director',
    memberSince: 'since February 2020, reappointed July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Andrew Bainbridge',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Helene Maiche',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Odile Vidot',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Christophe Edmond',
    position: 'Director and Chief Executive Officer',
    memberSince: 'since July 2022',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Philippe Pierre',
    position: 'Director and Deputy Chief Executive Officer',
    memberSince: 'since October 2022',
    otherPositions: '',
    education: '',
  },
  {
    name: 'Oliver Bastienne',
    position: 'Director',
    memberSince: 'since July 2021',
    otherPositions: '',
    education: '',
  },
];

// Data for Executive Management
const IMBexecutiveData = [
  {
    name: 'Christophe Edmond',
    position: 'Chief Executive Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Philippe Pierre',
    position: 'Deputy Chief Executive Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Joel Isaac',
    position: 'Chief Risk Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Mariza Tirant',
    position: 'Chief Financial Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Sandra Hall',
    position: 'Chief Compliance Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Anil Amplehy Ladouce',
    position: 'Chief Treasury Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Laurie-Mae Lepathy',
    position: 'Chief Corporate and Marketing Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Madeleine Aalfs',
    position: 'Chief Human Resources Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Laurain Confait',
    position: 'Chief Operations Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Maureen Brioche',
    position: 'Chief Branch Officer',
    startDate: '',
    previousPosition: '',
    education: '',
  },
];

const LETboardData = [
  {
    name: 'Philip Odera',
    position: 'Board Chairman and Independent Non-executive Director',
    memberSince: 'Board member since 2019, Chairman since 2022',
    previousPosition: "Executive Partner at Titans D'Afrique",
    education: 'Bachelor of Economics from St Lawrence University, US',
  },
  {
    name: 'Catherine Lesetedi',
    position: 'Non-executive Director',
    memberSince: 'Board member since 2017',
    previousPosition: 'Group CEO of Botswana Insurance Holdings Limited (BIHL)',
    education:
      'Bachelor of Arts in Statistics and Demography - University of Botswana',
  },
  {
    name: 'Abiodun Odubola',
    position: 'Independent Non-executive Director',
    memberSince: 'Board member since 2019',
    previousPosition:
      'Held senior roles in FirstBank Nigeria, Ecobank Nigeria, Metropolitan Bank Nigeria, Citibank',
    education:
      'BSc Agricultural Economics - University of Ibadan and University of Lagos',
  },
  {
    name: 'Christopher Mokgware',
    position: 'Deputy Chairperson and Independent Non-executive Director',
    memberSince: 'Board member since 2022',
    previousPosition: 'Senior Governance and Compliance Manager for De Beers',
    education: 'Chartered Certified Accountant',
  },
];
const LETexecutiveData = [
  {
    name: 'Aobakwe Aupa Monyatsi',
    position: 'Group Chief Executive Officer',
    startDate: 'March 2020 as Group COO, appointed CEO in 2022',
    previousPositions:
      'Various leadership roles at Absa/Barclays Group including Managing Executive for digital innovation',
    education: 'Bachelor of Accounting - University of Botswana',
  },
  {
    name: 'Gwen Tinotenda Muteiwa',
    position: 'Group Chief Financial Officer',
    startDate: '2020',
    previousPositions: 'Managing Director for merchant bank in Zimbabwe',
    education: 'Bachelor of Commerce Accounting - Rhodes University',
  },
  {
    name: 'Richard Ochieng',
    position: 'Group Chief Risk and Compliance Officer',
    startDate: '',
    previousPositions: 'Organizational ethics and compliance',
    education: '',
  },
  {
    name: 'Christopher Hughes',
    position: 'Group Chief Operations Officer',
    startDate: '',
    previousPositions: 'Data platform manager',
    education: '',
  },
];

const MCBboardData = [
  {
    name: 'Jean-François DESVAUX DE MARIGNY',
    position: 'Chairperson',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Uday GUJADHUR',
    position: 'Independent Non-Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Johanne HAGUE',
    position: 'Independent Non-Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Philippe LEDESMA',
    position: 'Independent Non-Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Craig McKENZIE',
    position: 'Independent Non-Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Su Lin ONG',
    position: 'Independent Non-Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Simon WALKER',
    position: 'Independent Non-Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Jean Michel NG TSEUNG',
    position: 'Non-Executive Director',
    memberSince: '',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Alain LAW MIN',
    position: 'Executive Director',
    memberSince: 'until December 2023',
    previousPosition: '',
    education: '',
  },
  {
    name: 'Thierry HEBRAUD',
    position: 'Executive Director',
    memberSince: '',
    previousPosition: '',
    qualification: '',
  },
];

const MCBexecutiveData = [
  {
    name: 'Vincent CHATARD',
    position: 'Chief Operating Officer',
    startDate: 'Since September 2015',
    previousPosition:
      'COO, Chief Business Development Officer and Executive Board Director at MeDirect Bank',
    education: 'Master of Science in Engineering',
  },
  {
    name: 'Allan FREED',
    position: 'Head of Human Resources',
    startDate: 'since March 2024',
    previousPosition: '15 years at the RBL Group',
    education: 'BA (Honours) in Political Science (UK)',
  },
  {
    name: 'Bhavish NAECK',
    position: 'Head of Finance',
    startDate: 'since January 2014',
    previousPosition:
      'Joined MCB in May 2000 as Manager – Investment Administration',
    education: 'BSc (Honours) in Economics',
  },
  {
    name: 'Frederic PAPOCCHIA',
    position: 'Chief Risk Officer',
    startDate: 'since January 2016',
    previousPosition:
      'Joined MCB in July 2012 as Consultant Head of Institutional',
    education: "Master's Degree in Finance and MBA (France)",
  },
  {
    name: 'Philippe TOUATI',
    position: 'Head of Corporate and Institutional Banking',
    startDate: 'Sep-23',
    previousPosition: 'Banking at ANZ Bank',
    education: 'MSc in Mathematics (France), MSc in Electrical Engineering',
  },
  {
    name: 'Parikshat TULSIDAS',
    position: 'Head of Financial Markets',
    startDate: 'since 2021',
    previousPosition:
      '20+ years experience in Financial Markets and Corporate & Investment Banking',
    education: 'BA (Honours) Human Resource Management and Marketing (UK)',
  },
  {
    name: 'Anju UMROWSING-RAMTOHUL',
    position: 'Head of Domestic Banking',
    startDate: 'since July 2024',
    previousPosition: 'Head of Credit Origination and Structuring',
    education:
      'MSc in Economics and Post Graduate Diploma in Banking and Finance (France)',
  },
];

const DirectorsTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [executiveData, setExecutiveData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  useEffect(() => {
    if (state?.name === 'FIRST NATIONAL BANK OF ESWATINI LIMITED') {
      setBoardData(FNBboardData);
      setExecutiveData(FNBexecutiveData);
    } else if (state?.name === 'ABC HOLDINGS LIMITED') {
      setExecutiveData(ABCexecutiveData);
      setBoardData(ABCboardData);
    } else if (
      state?.name ===
      'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ'
    ) {
      setExecutiveData(IMBexecutiveData);
      setBoardData(IMBboardData);
    } else if (state?.name === 'LETSHOGO HOLDING') {
      setExecutiveData(LETexecutiveData);
      setBoardData(LETboardData);
    } else if (
      state?.name === 'MAURITIUS COMMERCIAL BANK (MADAGASCAR) S.A. (THE)'
    ) {
      setExecutiveData(MCBexecutiveData);
      setBoardData(MCBboardData);
    }
  }, [state]);
  return (
    <Grid container spacing={2} mt={5}>
      <Stack>
        <Box display="flex" mt={2} gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Country :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedCountry?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedCountry?.displayName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography> Bank :- </Typography>
            <Box
              height={'40px'}
              width={'40px'}
              borderRadius={'50%'}
              component={'img'}
              src={location.state?.selectedBank?.icon}
              sx={{ border: '1px solid #ccc' }}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.state?.selectedBank?.bankName}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {/* Board of Directors Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden', mb: 4 }}
        >
          <Table aria-label="board of directors table">
            <TableHead>
              <TableRow>
                <SectionHeaderCell colSpan={5}>
                  Board of Directors
                </SectionHeaderCell>
              </TableRow>
              <TableRow>
                <HeadTableCell>Name</HeadTableCell>
                <HeadTableCell>Board Position</HeadTableCell>
                <HeadTableCell>Board Member since (dd/mm/yy)</HeadTableCell>
                <HeadTableCell>
                  Other Current / Previous Positions
                </HeadTableCell>
                <HeadTableCell>Education / Other Qualification</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {boardData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.position}</StyledTableCell>
                  <StyledTableCell>{row.memberSince}</StyledTableCell>
                  <StyledTableCell>{row.previousPosition}</StyledTableCell>
                  <StyledTableCell>{row.education}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Executive Management Table */}
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
        >
          <Table aria-label="executive management table">
            <TableHead>
              <TableRow>
                <SectionHeaderCell colSpan={5}>
                  Executive Management
                </SectionHeaderCell>
              </TableRow>
              <TableRow>
                <HeadTableCell>Name</HeadTableCell>
                <HeadTableCell>Position</HeadTableCell>
                <HeadTableCell>
                  Start Date of Current Position (dd/mm/yy)
                </HeadTableCell>
                <HeadTableCell>
                  Previous Executive / Senior Management Positions
                </HeadTableCell>
                <HeadTableCell>Education / Other Qualification</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {executiveData.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.position}</StyledTableCell>
                  <StyledTableCell>{row.startDate}</StyledTableCell>
                  <StyledTableCell>{row.previousPosition}</StyledTableCell>
                  <StyledTableCell>{row.education}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} my={4} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={3}
        >
          <AnimateButton>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                navigate('/financial-loan-breakdown', { state: location.state })
              }
            >
              Prev
            </Button>
          </AnimateButton>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              // endIcon={<ArrowForwardIcon />}
            >
              Done
            </Button>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DirectorsTable;
