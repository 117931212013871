import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimateButton from '../../components/common/formStyle/AnimateButton';

// Import the image directly
import AbcBank from '../../assets/abc.png';
import Botswana from '../../assets/botswana.png';
import Eswatini from '../../assets/eswatini.png';
import FNBBank from '../../assets/fnbBank.jpeg';
import Namibia from '../../assets/namibia.png';
import Seychelles from '../../assets/seychelles.png';
import Madagascar from '../../assets/Flag-Madagascar.jpg';
import IMB from '../../assets/imb.jpg';
import MCB from '../../assets/mcb.png';
import LETSHEGO from '../../assets/LETSHEGO.jpg';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid #132933',
}));

// Full bank list
const bankList = [
  {
    bankName: 'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ',
    displayName: 'SEYCHELLES INTERNATIONAL MERCANTILE BANKING CORP. NOUVOBANQ',
    icon: IMB,
    country: 'seychelles',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 12,

        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: '+ Total Capital / Total Risk weigted assets',
        y1: 2743528,
        y2: 2839766,
        y3: '19%',
        y4: '19%',
        y5: 287446,
        y6: 448540,
        y7: 'N/A',
        y8: '19.36%',
      },
      {
        id: 'a2',
        resultLabel: 'NPL Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: '+ Non-Performing Loans /Gross Loans',
        y1: 36,
        y2: 89381,
        y3: '0%',
        y4: '3%',
        y5: 105156,
        y6: 182448,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 16268,
        y2: 41415,
        y3: '45189%',
        y4: '46%',
        y5: 181970,
        y6: 200587,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          '+  Total Capital (shareholders equity)/ Average banking Assets',
        y1: 841579,
        y2: 1042661,
        y3: '9.37%',
        y4: '10.55%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity  (ROE) %',
        calculationLabel: ' Total Assets ',
        tooltip: '+ Operating Expenses/ Total Capital (shareholders equity)',
        y1: 26858,
        y2: 36546,
        y3: '27.41%',
        y4: '34.39%',
        y5: 11193381,
        y6: 12367099,
        y7: '26.6%',
        y8: 'N/A',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (ROA) %',
        calculationLabel: ' Average banking Assets',
        tooltip: '+ Operating Expenses/ Total Assets',
        y1: 841579 + 26858,
        y2: 1079207,
        y3: '3%',
        y4: '4%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income  %',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          '+ Non-Interest Income) (excludes loss on sale of assets)/ Sum(Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets+Operating Expenses)',
        y1: 4485097,
        y2: 5757026,
        y3: '22.40%',
        y4: '28.11%',
        y5: 9971765,
        y6: 10913951,
        y7: 'N/A',
        y8: '26.5%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          '+ Gross Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '97%',
        y2: '97%',
        y3: '0.28%',
        y4: '0.26%',
        y5: 4398740,
        y6: 4043922,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          '+ High Liquidity Assets/Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '91%',
        y2: '90%',
        y3: '43.36%',
        y4: '36.55%',
        y5: 10144826,
        y6: 11062670,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '+ High Liquidity Assets/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '53%',
        y2: '50%',
        y3: '0.44%',
        y4: '0.37%',
        y5: 2214504,
        y6: 1863826,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: ' Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 1048555,
        y6: 1304429,
      },
    ],
  },
  {
    bankName: 'FIRST NATIONAL BANK OF ESWATINI LIMITED',
    displayName: 'FIRST NATIONAL BANK OF ESWATINI LIMITED',
    icon: FNBBank,
    country: 'eswatini',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 8,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: '+ Total Capital / Total Risk weigted assets',
        y1: 2996970,
        y2: 3635599,
        y3: '23%',
        y4: '20%',
        y5: 341637,
        y6: 449191,
        y7: '24.78%',
        y8: 'N/A',
      },
      {
        id: 'a2',
        resultLabel: 'NPL Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: '+ Non-Performing Loans /Gross Loans',
        y1: 105571,
        y2: 107995,
        y3: '4%',
        y4: '3%',
        y5: 479803,
        y6: 542395,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 158769,
        y2: 149688,
        y3: '150%',
        y4: '139%',
        y5: 428087,
        y6: 464813,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          '+  Total Capital (shareholders equity)/ Average banking Assets',
        y1: 1039478,
        y2: 1120472,
        y3: '12.54%',
        y4: '13.89%',
        y5: '23%',
        y6: '20%',
        y7: '13%',
        y8: 'N/A',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity  (ROE) %',
        calculationLabel: ' Total Assets ',
        tooltip: '+ Operating Expenses/ Total Capital (shareholders equity)',
        y1: 42526,
        y2: 48911,
        y3: '44.35%',
        y4: '46.39%',
        y5: 8623686,
        y6: 8414515,
        y7: 'N/A',
        y8: '20.3%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (ROA) %',
        calculationLabel: ' Average banking Assets',
        tooltip: '+ Operating Expenses/ Total Assets',
        y1: 1039478 + 42526,
        y2: 1120472 + 48911,
        y3: '6%',
        y4: '6%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income  %',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          '+ Non-Interest Income) (excludes loss on sale of assets)/ Sum(Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets+Operating Expenses)',
        y1: 4620088,
        y2: 5928790,
        y3: '82.22%',
        y4: '85.70%',
        y5: 6586885,
        y6: 5171237,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          '+ Gross Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '96%',
        y2: '96%',
        y3: '0.45%',
        y4: '0.70%',
        y5: 2714361,
        y6: 1141595,
        y7: '49.3%',
        y8: '57.0',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          '+ High Liquidity Assets/Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '36%',
        y2: '31%',
        y3: '35.99%',
        y4: '15.76%',
        y5: 7541913,
        y6: 7245361,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '+ High Liquidity Assets/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '51%',
        y2: '88%',
        y3: '0.41',
        y4: '0.22',
        y5: 2695858,
        y6: 1129540,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: 'Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 1081773,
        y6: 1169154,
      },
    ],
  },
  {
    bankName: 'ABC HOLDINGS LIMITED',
    displayName: 'ABC HOLDINGS LIMITED',
    icon: AbcBank,
    country: 'botswana',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 12.5,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: '+ Total Capital / Total Risk weigted assets',
        y1: 6820608,
        y2: 6362917,
        y3: '20%',
        y4: '22%',
        y5: 399167,
        y6: 408768,
        y7: '19.00%',
        y8: '19.92%',
      },
      {
        id: 'a2',
        resultLabel: 'NPL Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: '+ Non-Performing Loans /Gross Loans',
        y1: 303449,
        y2: 292263,
        y3: '4%',
        y4: '5%',
        y5: 504926,
        y6: 545820,
        y7: '3.11%',
        y8: '3.63%',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 243051,
        y2: 232020,
        y3: '80%',
        y4: '79%',
        y5: 152304,
        y6: 204028,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          '+  Total Capital (shareholders equity)/ Average banking Assets',
        y1: 921840,
        y2: 996639,
        y3: '11%',
        y4: '11%',
        y5: '20%',
        y6: '22%',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity  (ROE) %',
        calculationLabel: ' Total Assets ',
        tooltip: '+ Operating Expenses/ Total Capital (shareholders equity)',
        y1: 496356,
        y2: 451161,
        y3: '40%',
        y4: '39%',
        y5: 9308248,
        y6: 9850811,
        y7: '22.9%',
        y8: '23.1%',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (ROA) %',
        calculationLabel: ' Average banking Assets',
        tooltip: '+ Operating Expenses/ Total Assets',

        y1: 921840 + 496356,
        y2: 996639 + 451161,
        y3: '4%',
        y4: '4%',
        y5: 3653947,
        y6: 3823119,
        y7: '1.83',
        y8: '1.87',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income  %',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          '+ Non-Interest Income) (excludes loss on sale of assets)/ Sum(Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets+Operating Expenses)',
        y1: 6953331,
        y2: 6651776,
        y3: '92%',
        y4: '89%',
        y5: 7468283,
        y6: 7748789,
        y7: '58.3%',
        y8: '52.8%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          '+ Gross Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '65%',
        y2: '69%',
        y3: '0.91%',
        y4: '0.82%',
        y5: 2335596,
        y6: 3225108,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          '+ High Liquidity Assets/Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '84.8%',
        y2: '85.4%',
        y3: '28.11%',
        y4: '36.61%',
        y5: 8310228,
        y6: 8809229,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '+ High Liquidity Assets/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '40.2%',
        y2: '44.6%',
        y3: '0.31',
        y4: '0.42',
        y5: 1438425,
        y6: 2080879,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: ' Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 998020,
        y6: 1041582,
      },
    ],
  },
  {
    bankName: 'LETSHOGO HOLDING',
    displayName: 'LETSHOGO HOLDING',
    icon: LETSHEGO,
    country: 'namibia',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 12,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: '+ Total Capital / Total Risk weigted assets',
        y1: 4801245,
        y2: 4783462,
        y3: '54%',
        y4: '45%',
        y5: 453426,
        y6: 438209,
        y7: 'N/A',
        y8: 'N/A',
        limit: 12,
      },
      {
        id: 'a2',
        resultLabel: 'NPL Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: '+ Non-Performing Loans /Gross Loans',
        y1: 237227,
        y2: 302601,
        y3: '5%',
        y4: '6%',
        y5: 268334,
        y6: 262301,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 48543,
        y2: 43155,
        y3: '20%',
        y4: '14%',
        y5: 52721,
        y6: 38143,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel:
          'Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets',
        tooltip:
          '+  Total Capital (shareholders equity)/ Average banking Assets',
        y1: 2709946,
        y2: 2633193,
        y3: '46.38%',
        y4: '40.37%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity  (ROE) %',
        calculationLabel: ' Total Assets ',
        tooltip: '+ Operating Expenses/ Total Capital (shareholders equity)',

        y1: 130692,
        y2: -13074,
        y3: '16.27%',
        y4: '15.97%',
        y5: 6007163,
        y6: 6796373,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (ROA) %',
        calculationLabel: ' Average banking Assets',
        tooltip: '+ Operating Expenses/ Total Assets',

        y1: 2840638,
        y2: 2620119,
        y3: '8%',
        y4: '6%',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income  %',
        calculationLabel:
          ' Customer Deposits (includes institutional and intergroup deposits)',
        tooltip:
          '+ Non-Interest Income) (excludes loss on sale of assets)/ Sum(Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets+Operating Expenses)',
        y1: 5220181,
        y2: 5767768,
        y3: '53.02%',
        y4: '55.06%',
        y5: 535687,
        y6: 827978,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit ',
        calculationLabel: 'High Liquidity Assets',
        tooltip:
          '+ Gross Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '95%',
        y2: '100%',
        y3: '8.96',
        y4: '5.78',
        y5: 969525,
        y6: 1663923,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities ',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          '+ High Liquidity Assets/Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '53%',
        y2: '51%',
        y3: '30.10%',
        y4: '41.06%',
        y5: 3221030,
        y6: 4052839,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '+ High Liquidity Assets/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '57%',
        y2: '51%',
        y3: '1.81',
        y4: '2.01',
        y5: 320815,
        y6: 750849,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a11',
        resultLabel: '',
        calculationLabel: ' Total Capital (shareholders equity)',
        y1: '',
        y2: '',
        y3: '',
        y4: '',
        y5: 2786133,
        y6: 2743534,
      },
    ],
  },
  {
    bankName: 'MAURITIUS COMMERCIAL BANK (MADAGASCAR) S.A. (THE)',
    displayName: 'MAURITIUS COMMERCIAL BANK (MADAGASCAR) S.A. (THE)',
    icon: MCB,
    country: 'madagascar',
    header: [2022, 2023],
    enabled: true,
    financialData: [
      {
        id: 'a1',
        limit: 8,
        resultLabel: 'Capital Adequacy Ratio',
        calculationLabel: 'Net Interest Income/Revenue',
        tooltip: '+ Total Capital / Total Risk weigted assets',
        y1: 339564,
        y2: 367765,
        y3: '18%',
        y4: '19%',
        y5: 15191,
        y6: 19790,
        y7: 'N/A',
        y8: '19.2%',
      },
      {
        id: 'a2',
        resultLabel: 'NPL Ratio',
        calculationLabel: 'Operating Expenses',
        tooltip: '+ Non-Performing Loans /Gross Loans',
        y1: 14331,
        y2: 13636,
        y3: '4%',
        y4: '4%',
        y5: 9132,
        y6: 11257,
        y7: '3.7%',
        y8: 'N/A',
      },
      {
        id: 'a3',
        resultLabel: 'Provision for Loan Loss ratio',
        calculationLabel:
          'Non-Interest Income) (excludes loss on sale of assets)',
        tooltip: '+ Provision for Loan Loss / Non-Performing Loans',
        y1: 13951,
        y2: 18480,
        y3: '97%',
        y4: '136%',
        y5: 8654,
        y6: 12002,
        y7: 'N/A',
        y8: '0.86%',
      },
      {
        id: 'a4',
        resultLabel: 'Equity to Total Assets ',
        calculationLabel: ' Total Assets ',
        tooltip:
          '+  Total Capital (shareholders equity)/ Average banking Assets',
        y1: 77444,
        y2: 87771,
        y3: '11.13%',
        y4: '11.22%',
        y5: 728128,
        y6: 829981,
        y7: 'N/A',
        y8: '11.2%',
      },
      {
        id: 'a5',
        resultLabel: 'Return on Equity  (ROE) %',
        calculationLabel:
          '  Customer Deposits (includes institutional and intergroup deposits)',
        tooltip: '+ Operating Expenses/ Total Capital (shareholders equity)',

        y1: 6776,
        y2: 13794,
        y3: '18.74%',
        y4: '21.26%',
        y5: 518677,
        y6: 587414,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a6',
        resultLabel: 'Return on Assets (ROA) %',
        calculationLabel: 'High Liquidity Assets',
        tooltip: '+ Operating Expenses/ Total Assets',

        y1: 84220,
        y2: 101565,
        y3: '2%',
        y4: '2%',
        y5: 73771,
        y6: 121853,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a7',
        resultLabel: 'Cost to Income  %',
        calculationLabel:
          'Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        tooltip:
          '+ Non-Interest Income) (excludes loss on sale of assets)/ Sum(Tier1 Capital+ Tier 2 capital)/ Risk Weighted Assets+Operating Expenses)',
        y1: 461859,
        y2: 524826,
        y3: '38.30%',
        y4: '35.41%',
        y5: 647086,
        y6: 736886,
        y7: '38.3%',
        y8: '35.4%',
      },
      {
        id: 'a8',
        resultLabel: 'Total Loans to customer deposit ',
        calculationLabel: '(Cash and Cash Equivalents)',
        tooltip:
          '+ Gross Loans/ Customer Deposits (includes institutional and intergroup deposits)',
        y1: '92%',
        y2: '86%',
        y3: '0.65',
        y4: '0.63',
        y5: 73294,
        y6: 120570,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a9',
        resultLabel: 'Liquid Assets to Net Liabilities ',
        calculationLabel: ' Total Capital (shareholders equity)',
        tooltip:
          '+ High Liquidity Assets/Total Liabilities excluding Equity (only used qualifying liquid assets perBA 300)',
        y1: '33%',
        y2: '33#%',
        y3: '11.40%',
        y4: '16.54%',
        y5: 81042,
        y6: 93095,
        y7: 'N/A',
        y8: 'N/A',
      },
      {
        id: 'a10',
        resultLabel: 'Liquid assets to  total deposits ',
        tooltip:
          '+ High Liquidity Assets/ Customer Deposits (includes institutional and intergroup deposits)',
        calculationLabel: '',
        y1: '50%',
        y2: '53%',
        y3: '0.14',
        y4: '0.21',
        y5: '',
        y6: '',
        y7: 'N/A',
        y8: 'N/A',
      },
    ],
  },
];

// Full country list with currency information
const selectCountry = [
  // {
  //   bankName: 'nigeria',
  //   displayName: 'Nigeria',
  //   icon: NigeriaFlag,
  //   currencySymbol: '₦',
  //   currencyName: 'NGN',
  //   enabled: true,
  // },
  {
    bankName: 'seychelles',
    displayName: 'Seychelles',
    icon: Seychelles,
    currencySymbol: '₨',
    currencyName: 'SCR',
    enabled: true,
  },
  {
    bankName: 'namibia',
    displayName: 'Namibia',
    icon: Namibia,
    currencySymbol: '$',
    currencyName: 'NAD',
    enabled: true,
  },
  {
    bankName: 'botswana',
    displayName: 'Botswana',
    icon: Botswana,
    currencySymbol: 'P',
    currencyName: 'BWP',
    enabled: true,
  },
  {
    bankName: 'eswatini',
    displayName: 'Eswatini',
    icon: Eswatini,
    currencySymbol: 'L',
    currencyName: 'SZL',
    enabled: true,
  },
  {
    bankName: 'madagascar',
    displayName: 'Madagascar',
    icon: Madagascar,
    currencySymbol: 'M',
    currencyName: 'MGA',
    enabled: true,
  },
];

const tableRows = [
  {
    id: 'a1',
    label: 'Gross Loans',
  },
  {
    id: 'a2',
    label: 'Non-Performing Loans',
  },
  {
    id: 'a3',
    label: 'Provision for Loan Loss ',
  },
  {
    id: 'a4',
    label: 'Tier 1 Capital',
  },
  {
    id: 'a5',
    label: 'Tier 2 Capital',
  },
  {
    id: 'a6',
    label: 'Total Capital',
  },
  {
    id: 'a7',
    label: 'Total Risk-weighted Assets',
  },
  {
    id: 'a8',
    label: 'Tier 1 Capital Ratio %',
  },
  {
    id: 'a9',
    label: 'Top 20 loans Concentration level %',
  },
  {
    id: 'a10',
    label: 'Top 20 depositors concentration level %',
  },
];

const FinancialInstitution = () => {
  const navigate = useNavigate();
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for select all
  const [selectedBankLogo, setSelectedBankLogo] = useState(bankList[2]?.icon);
  const [selectedBank, setSelectedBank] = useState(bankList[2]);
  const [selectedCountryLogo, setSelectedCountryLogo] = useState(Botswana); // Set default country logo to Nigeria
  const [filteredBanks, setFilteredBanks] = useState([]); // State for filtered banks
  const [currencySymbol, setCurrencySymbol] = useState('P'); // Default currency symbol to NGN
  const [currencyName, setCurrencyName] = useState('BWP'); // Default currency name to NGN

  // Handle row selection for checkboxes
  const handleRowClick = (index) => {
    setCheckedRows((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const isChecked = (index) => checkedRows.includes(index);

  // Handle Select All checkbox
  const handleSelectAllClick = () => {
    if (selectAll) {
      // Uncheck all rows
      setCheckedRows([]);
    } else {
      // Select all rows
      setCheckedRows(tableRows.map((_, index) => index));
    }
    setSelectAll(!selectAll);
  };

  // Filter banks based on selected country
  const handleCountryChange = (e, value) => {
    setSelectedCountryLogo(value.icon);
    setCurrencySymbol(value.currencySymbol); // Set the selected country's currency symbol
    setCurrencyName(value.currencyName); // Set the selected country's currency name
    // Filter banks based on the country selected
    const filtered = bankList.filter((bank) => bank.country === value.bankName);
    setFilteredBanks(filtered);
    setSelectedBank(null);
    setSelectedBankLogo(null); // Reset selected bank logo
  };

  // Get selected data from the table rows
  const selectedData = tableRows.filter((_, index) =>
    checkedRows.includes(index)
  );

  // Handle Next button click and pass selected data and currency info to the next route
  const handleNextClick = () => {
    let selectedCountry = selectCountry.find(
      (country) => country.icon === selectedCountryLogo
    );
    const allTable = bankList.find((i) => i.bankName === selectedBank.bankName);
    const data = selectedData.map((i) => {
      return {
        ...i,
        ...(allTable?.financialData?.length &&
          allTable?.financialData.find((j) => j.id === i.id)),
      };
    });
    navigate('/financial-result', {
      state: {
        selectedData: data,
        header: selectedBank?.header,
        name: selectedBank?.bankName,
        currencySymbol: selectedCountry.currencySymbol,
        currencyName: selectedCountry.currencyName,
        selectedCountry,
        selectedBank,
        calculation:
          allTable?.financialData[allTable?.financialData?.length - 1],
      },
    });
  };

  // Set default country to Nigeria on component load
  useEffect(() => {
    const defaultCountry = selectCountry.find(
      (country) => country.bankName === 'botswana'
    );
    setFilteredBanks(bankList.filter((bank) => bank.country === 'botswana'));
    setCurrencySymbol(defaultCountry.currencySymbol);
    setCurrencyName(defaultCountry.currencyName);
  }, []);

  return (
    <Box>
      <Grid
        container
        my={3}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        textAlign={'center'}
        spacing={3}
      >
        {/* Select Country Section */}
        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Country
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-country"
                options={selectCountry}
                disableClearable
                defaultValue={selectCountry[2]} // Default to Nigeria
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName || ''}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      height="48" // Ensure width and height are the same
                      style={{ borderRadius: '50%' }} // This ensures the image is round
                      src={option.icon}
                      alt={option.displayName}
                    />
                    {option.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Country"
                    hiddenLabel
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedCountryLogo && (
                            <Box
                              component="img"
                              src={selectedCountryLogo}
                              alt=""
                              sx={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
                onChange={handleCountryChange} // Filter banks based on country selection
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Select Bank Section */}
        <Grid item xs={12}>
          <Typography variant="Header_semibold_48">
            Select Financial Institution
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack flexDirection={'column'} justifyContent={'space-between'}>
            <Typography
              mb={1}
              variant="Body_medium_16"
              color="common.white"
              textAlign={'start'}
            >
              Select Bank
            </Typography>
            <Grid item sx={{ width: '300px' }}>
              <Autocomplete
                id="select-bank"
                value={selectedBank || null} // Default to Nigeria
                options={filteredBanks} // Use filtered banks based on the country selected
                disableClearable
                getOptionDisabled={(option) => !option?.enabled}
                getOptionLabel={(option) => option.displayName || ''}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    name={option.bankName}
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      name={option.bankName}
                      width="48"
                      style={{ borderRadius: '50%' }}
                      src={option.icon}
                      alt={option.displayName}
                    />
                    {option.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    id="filled-hidden-label-normal"
                    placeholder="Select Bank"
                    hiddenLabel
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {selectedBankLogo && (
                            <Box
                              component="img"
                              src={selectedBankLogo}
                              alt=""
                              sx={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '50%',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
                onChange={(e, value) => {
                  setSelectedBankLogo(value.icon); // Dynamically set the bank logo based on selection
                  setSelectedBank(value); // Dynamically set the bank logo based on selection
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: '12px', overflow: 'hidden' }}
          >
            <Table
              sx={{
                minWidth: 650,
                width: '100%',
              }}
              aria-label="financial table"
            >
              <TableHead
                sx={{
                  background: '#243842',
                }}
              >
                <TableRow>
                  <HeadTableCell align="center">
                    Select All
                    <Checkbox
                      checked={selectAll}
                      indeterminate={
                        checkedRows.length > 0 &&
                        checkedRows.length < tableRows.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </HeadTableCell>
                  <HeadTableCell>In {currencyName} '000 </HeadTableCell>{' '}
                  {selectedBank?.header?.length &&
                    selectedBank?.header.map((item) => (
                      <>
                        <HeadTableCell align="right">{item}</HeadTableCell>{' '}
                      </>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  background: '#001823',
                }}
              >
                {tableRows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { borderBottom: 0 },
                      cursor: 'pointer',
                      background: isChecked(index) ? '#042331' : 'inherit',
                    }}
                    onClick={() => handleRowClick(index)}
                  >
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={isChecked(index)}
                        onChange={() => handleRowClick(index)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.label}
                    </StyledTableCell>
                    <StyledTableCell align="right">------</StyledTableCell>
                    <StyledTableCell align="right">------</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
          <AnimateButton>
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={handleNextClick}
              disabled={!selectedData?.length}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: 'danger.light', // Change background color when disabled
                  color: 'white', // Change text color when disabled
                },
              }}
            >
              Next
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialInstitution;
